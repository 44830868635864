(function() {
  'use strict';

  /** @ngInject */
  function SearchController(searchService, $location, $window, $rootScope, $scope, $translate, $q, $timeout) {
    var vm  = this;
    var _locationListener       = null;
    var _lastSearch             = null;

    // Public variables
    vm.data                     = {};
    vm.form                     = null;
    vm.sendingData              = false;
    vm.searchResults            = [];

    //public function definitions
    vm.send = send;

    var searchContainer = document.querySelector('.js-search-container');

    $scope.$watch(function() {
      var matches = $location.hash().match(/q=(.*)/);
      return matches[1];
    }, function(value, oldValue) {
      if (value && value !== oldValue) {
        var queryInput = document.querySelector('#oi-query');
        var submitButton = document.querySelector('#oi-submit');
        // vm.data.query = value;
        queryInput.value = value;
        $timeout(function() {
          queryInput.dispatchEvent(new Event('change'));
          submitButton.dispatchEvent(new Event('click'));
          window.location.reload();
        }, 1000);

      }
    });

    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var translatePromises = [
      $translate('search.many_results'),
      $translate('search.few_results'),
      $translate('search.one_result'),
      $translate('search.no_results_for_query'),
      $translate('search.previous'),
      $translate('search.next')
    ];

    $q.all(translatePromises).then(function(translations) {
      $window.sitesearch(function(openindex) {
        var $searchForm = searchContainer.querySelector('.search-form');
        var $searchInput = searchContainer.querySelector('.search-input');
        var $openSearch = document.querySelector('.js-open-search');

        function openSearch() {
          searchContainer.classList.toggle('active');
          document.body.classList.add('no-scroll');
          if ($searchInput) $searchInput.focus();
        }

        openindex.lang.restrict = 'nl';
        openindex.result.showAddr = '';
        openindex.result.mm = 0;
        openindex.pager.manyResults = translations[0];
        openindex.pager.fewResults = translations[1];
        openindex.pager.oneResult = translations[2];
        openindex.pager.noResults = translations[3];
        openindex.pager.prevLabel = translations[4];
        openindex.pager.nextLabel = translations[5];
        openindex.useQueryStringParameters = true;

        // 20190527 - rob@ivengi.com -  'Misuse' template function to add parsed result data to array to make
        //                              Angular rendering possible.
        openindex.result.template = function (doc, url, title, addr, snippet, links, rank, image, time) {
          var div = document.createElement(('DIV'));
          div.innerHTML = title;

          $scope.$apply(function() {
            vm.searchResults.push({
              doc: doc,
              url: getParameterByName('url', url) || url,
              title: div.textContent || div.innerText || '',
              addr: addr,
              snippet: snippet,
              links: links,
              rank: rank,
              image: image,
              time: time
            });
          });
        };

        // openindex.result.addrLink = true;
        openindex.useQueryStringParameters = false;

        if ($openSearch) {
          $openSearch.onclick = function (e) {
            e.preventDefault();
            openSearch();
          };
        }

        openindex.notfound.defaultText = $translate.instant('search.no_result');
        openindex.notfound.text = $translate.instant('search.no_suggestions');

      });

    });
    //public functions
    function send(){
      vm.sendingData = true;
      $location.search('q', vm.data.query);
      if(vm.data.query !== _lastSearch){
        vm.searchResults = [];
        searchService.search(vm.data.query).then(function(response){
          $rootScope.$broadcast('wml.portal.message.cancel');
          if(response.webPages){
            vm.searchResults = response.webPages.value;
          }
        }).finally(function(){
          vm.sendingData = false;
          _lastSearch = vm.data.query;
        });
      } else {
        vm.sendingData = false;
      }
    }

    function search() {
      var location = $location.search();
      if(angular.isDefined(location.q)){
        vm.data.query = location.q;
        if(vm.data.query.length){
          if(vm.data.query !== _lastSearch){
            searchService.search(vm.data.query).then(function(response){
              $rootScope.$broadcast('wml.portal.message.cancel');
              if(response.webPages){
                vm.searchResults = response.webPages.value;
              }
            }).finally(function(){
              _lastSearch = vm.data.query;
            })
          }
        }
      }
    }
  }

  angular
    .module('wml.portal.search')
    .controller('SearchController', SearchController);
})();
